import type { ChakraProps } from '@chakra-ui/react';
import { Image, Link as ChakraLink, Text } from '@chakra-ui/react';
import { EXTERNAL_LINK } from '@ui/config/externalLinks';
import { pathDict } from '@ui/config/paths';
import type { CoinMode } from '@ui/hooks/useCoinMode';
import { COIN_MODE } from '@ui/hooks/useCoinMode';
import type { TranslationFunction } from '@ui/i18n';
import Link from '@ui/overrides/next/link';
import type { FC, ReactNode } from 'react';

export type VnoHighlightItem = {
  Icon: FC<ChakraProps>;
  title: ReactNode;
  desc: ReactNode;
};
export const getVenoHighlights = (
  t: TranslationFunction,
  coinMode: CoinMode,
): VnoHighlightItem[] => {
  const coinModeInfo = COIN_MODE[coinMode];

  const ethHighlights: VnoHighlightItem[] = [
    {
      Icon: (props) => (
        <Image src="/images/vno-highlight-eth.svg" alt="icon" {...props} />
      ),
      title: t('Stake & Redeem ETH directly on ZkSync Era'),
      desc: (
        <Text>
          {t(
            'Stake your ETH held on ZkSync Era for LETH and start earning staking rewards',
          )}
        </Text>
      ),
    },
    {
      Icon: (props) => (
        <Image
          src="/images/vno-highlight-lucrative.svg"
          alt="icon"
          {...props}
        />
      ),
      title: t('Lucrative Incentive Program'),
      desc: (
        <Text>
          {t('Lucrative-Incentive-Program-desc-{Link}', {
            Link: (chunks) => (
              <Link href={pathDict[coinMode].vno} passHref>
                <ChakraLink color="text-link">{chunks}</ChakraLink>
              </Link>
            ),
          })}
        </Text>
      ),
    },
    {
      Icon: (props) => (
        <Image
          src="/images/vno-highlight-tradability.svg"
          alt="icon"
          {...props}
        />
      ),
      title: t('Full tradability'),
      desc: <Text>{t('full-tradability-desc')}</Text>,
    },
    {
      Icon: (props) => (
        <Image src="/images/vno-highlight-reduce.svg" alt="icon" {...props} />
      ),
      title: t('Reduced Transaction Costs'),
      desc: <Text>{t('reduced-transaction-costs-desc')}</Text>,
    },
  ];

  const croHighlight: VnoHighlightItem = {
    Icon: (props) => (
      <Image src="/images/vno-highlight-top.svg" alt="icon" {...props} />
    ),
    title: t('#1 CRO Liquid Staking Provider'),
    desc: t('Over 70 Million CRO staked'),
  };

  const highlights: VnoHighlightItem[] = [
    {
      Icon: (props) => (
        <Image src="/images/vno-highlight-defi.svg" alt="icon" {...props} />
      ),
      title: t('Largest DeFi ecosystem in Cosmos'),
      desc: (
        <Text>
          {t('largest-defi-desc-{coin}-{liquidCoin}', {
            coin: coinModeInfo.name,
            liquidCoin: coinModeInfo.liquidName,
            link: (chunks) => (
              <Link href={pathDict[coinMode].vno} passHref>
                <ChakraLink color="text-link">{chunks}</ChakraLink>
              </Link>
            ),
          })}
        </Text>
      ),
    },
    {
      Icon: (props) => (
        <Image
          src="/images/vno-highlight-liquidity.svg"
          alt="icon"
          {...props}
        />
      ),
      title: t('Never Ending Liquidity'),
      desc: t('never-ending-desc-{coin}', {
        coin: coinModeInfo.name,
      }),
    },
    {
      Icon: (props) => (
        <Image
          src="/images/vno-highlight-community.svg"
          alt="icon"
          {...props}
        />
      ),
      title: t('Thriving Community'),
      desc: (
        <Text>
          {t('Thriving-Community-desc', {
            discordLink: (chunks) => (
              <Link href={EXTERNAL_LINK.discord} passHref>
                <ChakraLink color="text-link" isExternal>
                  {chunks}
                </ChakraLink>
              </Link>
            ),
            telegramLink: (chunks) => (
              <Link href={EXTERNAL_LINK.telegramAnnouncements} passHref>
                <ChakraLink color="text-link" isExternal>
                  {chunks}
                </ChakraLink>
              </Link>
            ),
          })}
        </Text>
      ),
    },
  ];

  const highlightsMap: Record<CoinMode, VnoHighlightItem[]> = {
    eth: ethHighlights,
    cro: [croHighlight, ...highlights],
    atom: highlights,
    tia: highlights.slice(1),
  };

  return highlightsMap[coinMode];
};
