/* eslint-disable import/no-unresolved */
/* eslint-disable import/export */

import 'swiper/css';
import 'swiper/css/pagination';

import { chakra } from '@chakra-ui/react';
import React, { Children, isValidElement } from 'react';
import { Pagination } from 'swiper';
import { Swiper as Swiper_ } from 'swiper/react';

export * from 'swiper/react';

const ChakraSwiper = chakra(Swiper_);

export type SwiperProps = React.ComponentProps<typeof ChakraSwiper>;
export const SwiperWithPagination = (props: SwiperProps) => {
  const validChildren = Children.toArray(props.children).filter((child) =>
    isValidElement(child),
  ) as React.ReactElement[];

  return (
    <ChakraSwiper
      width="100%"
      modules={[Pagination]}
      spaceBetween={0}
      slidesPerView={1}
      loop={validChildren.length > 1}
      pagination={{
        clickable: true,
        renderBullet: function (index, className) {
          return `<span style="background:white;width:10px;height:10px;display:block;margin:10px" class="${className}"></span>`;
        },
      }}
      pb={validChildren.length > 1 ? '60px' : 0}
      {...props}
      sx={{
        // extend slides's drag area to 100%
        '& .swiper-slide': {
          height: 'auto',
        },
        '& .swiper-pagination': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          bottom: 0,
        },
        ...props.sx,
      }}
    />
  );
};

const SCALE = 0.8;
const SLIDE_RATIO_WITH_VIEWPORT = 0.8;
export type SwiperWithScaleProps = SwiperProps & {
  scaleEnabled?: boolean;
  slidesPerView?: number;
};
export const SwiperWithScale = ({
  scaleEnabled = true,
  slidesPerView,
  ...props
}: SwiperWithScaleProps) => {
  return (
    <ChakraSwiper
      width="100%"
      slidesPerView={slidesPerView ?? 1 / SLIDE_RATIO_WITH_VIEWPORT}
      centeredSlides={true}
      centeredSlidesBounds={true}
      spaceBetween={scaleEnabled ? 0 : 16}
      className="swiper-3d"
      grabCursor={true}
      slideToClickedSlide={true}
      watchSlidesProgress={true}
      onSetTransition={(swiper, duration) => {
        swiper.slides.transition(duration).transition(duration);
      }}
      onSetTranslate={
        scaleEnabled
          ? (swiper) => {
              const { slides } = swiper;
              for (let i = 0; i < slides.length; i += 1) {
                const $slideEl = slides.eq(i);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const progress = $slideEl[0]['progress'];
                const scaleString = `scale(${
                  1 - Math.abs((1 - SCALE) * progress)
                })`;
                $slideEl.transform(`translate3d(0,0,0) ${scaleString}`);
              }
            }
          : undefined
      }
      px="32px"
      {...props}
      sx={{
        // extend slides's drag area to 100%
        '& .swiper-slide': {
          height: 'auto',
        },
        ...props.sx,
      }}
    />
  );
};
