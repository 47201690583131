import type { BoxProps, ImageProps } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import { useDisclaimerDrawer } from '@ui/components/DisclaimerDrawer';

type EcosystemItem = {
  logo: string;
  url: string;
  width: ImageProps['width'];
  height: ImageProps['height'];
};

export const EcosystemSupportList: EcosystemItem[] = [
  {
    logo: '/images/vvs-logo.png',
    url: 'https://vvs.finance',
    width: '190px',
    height: '32px',
  },
  {
    logo: '/images/ferro-logo.png',
    url: 'https://ferroprotocol.com',
    width: '180px',
    height: '35px',
  },
  {
    logo: '/images/tectonic-logo.png',
    url: 'https://tectonic.finance/markets/veno/',
    width: '170px',
    height: '35px',
  },
  {
    logo: '/images/minted-logo.png',
    url: 'https://minted.network/collections/cronos/0xb15533a0bc7c530d692a9660785226dfd3633965',
    width: '160px',
    height: '30px',
  },
  {
    logo: '/images/ebisus-bay-logo.png',
    url: 'https://app.ebisusbay.com/collection/veno-unstaked-cro',
    width: '180px',
    height: '50px',
  },
  {
    logo: '/images/single-text-logo.png',
    url: 'https://app.singlefinance.io/',
    width: '112px',
    height: '50px',
  },
  {
    logo: '/candy-city-text-logo.webp',
    url: 'https://candycity.finance/?chainId=25',
    width: '134px',
    height: '60px',
  },
  {
    logo: '/images/PWN_logo_black.svg',
    url: 'https://pwn.xyz/',
    width: '120px',
    height: '50px',
  },
  {
    logo: '/images/kaching-logo.svg',
    url: 'https://kaching.win/',
    width: '152px',
    height: '50px',
  },
  {
    logo: '/images/syncswap-text-logo.png',
    url: 'https://syncswap.xyz/',
    width: '202px',
    height: '50px',
  },
  {
    logo: '/images/maverick-text-logo.png',
    url: 'https://mav.xyz/',
    width: '184px',
    height: '50px',
  },
];

export type EcosystemSupportCardProps = EcosystemItem & BoxProps;

export const EcosystemSupportCard = ({
  logo,
  url,
  ...props
}: EcosystemSupportCardProps) => {
  return (
    <Box
      backgroundImage={logo}
      backgroundSize="contain"
      backgroundPosition="center center"
      backgroundRepeat="no-repeat"
      cursor="pointer"
      onClick={() => {
        useDisclaimerDrawer.getState().onOpen({
          url,
        });
      }}
      {...props}
    />
  );
};
