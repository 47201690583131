import type { BoxProps } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import { useEffect } from 'react';
import { create } from 'zustand';

import { InDesktop } from './MobileOrDesktop';

export type DesktopBgStore = {
  bgImageProps: Partial<BoxProps>;
  updateBgImage: (image: DesktopBgImage) => void;
  resetBgImage: () => void;
};

export const useDesktopBgStore = create<DesktopBgStore>((set) => ({
  bgImageProps: {},
  resetBgImage: () => set(() => ({ bgImageProps: {} })),
  updateBgImage: (image) =>
    set(() => ({ bgImageProps: getDesktopBgImage(image) })),
}));

export type DesktopBgImage =
  | 'PATTERN_1'
  | 'PATTERN_2'
  | 'PATTERN_2_ATOM'
  | 'PATTERN_3'
  | 'PATTERN_3_ATOM'
  | 'PATTERN_4'
  | 'PATTERN_4_SMALL'
  | 'STAKE_ETH_BG_DESKTOP'
  | 'UNSTAKE_ETH_BG_DESKTOP'
  | 'STAKE_TIA_BG_DESKTOP'
  | 'UNSTAKE_TIA_BG_DESKTOP';

export const getDesktopBgImage = (image: DesktopBgImage): Partial<BoxProps> => {
  const commonProps = {
    backgroundRepeat: 'no-repeat',
  };
  switch (image) {
    case 'PATTERN_1':
      return {
        bgImage: '/images/pattern-1-desktop.png',
        backgroundSize: '100vw auto',
        backgroundPosition: 'center 210px',
        ...commonProps,
      };
    case 'PATTERN_2':
      return {
        bgImage: '/images/pattern-2-desktop.png',
        backgroundSize: '55vw auto',
        backgroundPosition: 'right top',
        ...commonProps,
      };
    case 'PATTERN_2_ATOM':
      return {
        bgImage: '/images/unstake-atom-bg.svg',
        backgroundSize: '100vw auto',
        backgroundPosition: 'center top 100px',
        ...commonProps,
      };
    case 'PATTERN_3':
      return {
        bgImage: '/images/pattern-3-desktop.png',
        backgroundSize: '100vw auto',
        backgroundPosition: 'center',
        ...commonProps,
      };
    case 'PATTERN_3_ATOM':
      return {
        bgImage: '/images/stake-atom-bg.svg',
        backgroundSize: '100vw auto',
        backgroundPosition: 'center',
        ...commonProps,
      };
    case 'UNSTAKE_ETH_BG_DESKTOP':
      return {
        bgImage: '/images/unstake-eth-bg.svg',
        backgroundSize: '100vw auto',
        backgroundPosition: 'center top 100px',
        ...commonProps,
      };
    case 'STAKE_ETH_BG_DESKTOP':
      return {
        bgImage: '/images/eth-landing-bg-desktop.svg',
        backgroundSize: '100vw auto',
        backgroundPosition: 'center top 100px',
        ...commonProps,
      };
    case 'UNSTAKE_TIA_BG_DESKTOP':
      return {
        bgImage: '/images/unstake-tia-bg-desktop.svg',
        backgroundSize: '100vw auto',
        backgroundPosition: 'center top 100px',
        ...commonProps,
      };
    case 'STAKE_TIA_BG_DESKTOP':
      return {
        bgImage: '/images/stake-tia-bg-desktop.svg',
        backgroundSize: '100vw auto',
        backgroundPosition: 'center top 100px',
        ...commonProps,
      };
    case 'PATTERN_4':
      return {
        bgImage: '/images/pattern-4-desktop.png',
        backgroundSize: '75vw auto',
        backgroundPosition: 'center top',
        ...commonProps,
      };
    case 'PATTERN_4_SMALL':
    default:
      return {
        bgImage: '/images/pattern-4-desktop.png',
        backgroundSize: '50vw auto',
        backgroundPosition: '50% 60px',
        ...commonProps,
      };
  }
};

export type DesktopBgProps = {
  pattern: DesktopBgImage;
};

const DesktopBg = ({ pattern }: DesktopBgProps) => {
  const bgImageProps = useDesktopBgStore((store) => store.bgImageProps);
  const updateBgImage = useDesktopBgStore((store) => store.updateBgImage);
  const resetBgImage = useDesktopBgStore((store) => store.resetBgImage);

  useEffect(() => {
    updateBgImage(pattern);
    return () => resetBgImage();
  }, [updateBgImage, resetBgImage, pattern]);

  return (
    <InDesktop>
      <Box
        pos="fixed"
        top={0}
        left={0}
        w="100vw"
        h="100vh"
        zIndex={-1}
        {...bgImageProps}
      />
    </InDesktop>
  );
};

export default DesktopBg;
