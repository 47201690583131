import type { ChakraProps } from '@chakra-ui/react';
import { Image, Link as ChakraLink, Text } from '@chakra-ui/react';
import type { BigNumber } from '@ethersproject/bignumber';
import { useQuery } from '@tanstack/react-query';
import { EXTERNAL_LINK } from '@ui/config/externalLinks';
import { pathDict } from '@ui/config/paths';
import { useApy } from '@ui/hooks/useApr';
import type { CoinMode } from '@ui/hooks/useCoinMode';
import { COIN_MODE } from '@ui/hooks/useCoinMode';
import { useCoinUsdValue } from '@ui/hooks/useCoinUsdValue';
import type { TranslationFunction } from '@ui/i18n';
import { i18next, useTranslations } from '@ui/i18n';
import Link from '@ui/overrides/next/link';
import { useSpecificVenoSDK } from '@ui/providers/VenoSDKProvider';
import { getQuery } from '@ui/queries/queries';
import { QueryKey } from '@ui/queries/queryKey';
import { getNetworkConfig } from '@veno-app/wallet';
import type { FC, ReactNode } from 'react';

export type VnoHighlightItem = {
  Icon: FC<ChakraProps>;
  title: ReactNode;
  desc: ReactNode;
};
export const getVenoHighlights = (
  t: TranslationFunction,
  coinMode: CoinMode,
): VnoHighlightItem[] => {
  const coinModeInfo = COIN_MODE[coinMode];

  if (coinMode === 'eth') {
    return [
      {
        Icon: (props) => (
          <Image src="/images/vno-highlight-eth.svg" alt="icon" {...props} />
        ),
        title: t('Stake & Redeem ETH directly on ZkSync Era'),
        desc: (
          <Text>
            {t(
              'Stake your ETH held on ZkSync Era for LETH and start earning staking rewards',
            )}
          </Text>
        ),
      },
      {
        Icon: (props) => (
          <Image
            src="/images/vno-highlight-lucrative.svg"
            alt="icon"
            {...props}
          />
        ),
        title: t('Lucrative Incentive Program'),
        desc: (
          <Text>
            {t('Lucrative-Incentive-Program-desc-{Link}', {
              Link: (chunks) => (
                <Link href={pathDict[coinMode].vno} passHref>
                  <ChakraLink color="text-link">{chunks}</ChakraLink>
                </Link>
              ),
            })}
          </Text>
        ),
      },
      {
        Icon: (props) => (
          <Image
            src="/images/vno-highlight-tradability.svg"
            alt="icon"
            {...props}
          />
        ),
        title: t('Full tradability'),
        desc: <Text>{t('full-tradability-desc')}</Text>,
      },
      {
        Icon: (props) => (
          <Image src="/images/vno-highlight-reduce.svg" alt="icon" {...props} />
        ),
        title: t('Reduced Transaction Costs'),
        desc: <Text>{t('reduced-transaction-costs-desc')}</Text>,
      },
    ];
  }

  const croHighlight: VnoHighlightItem = {
    Icon: (props) => (
      <Image src="/images/vno-highlight-top.svg" alt="icon" {...props} />
    ),
    title: t('#1 CRO Liquid Staking Provider'),
    desc: t('Over 70 Million CRO staked'),
  };

  const highlights: VnoHighlightItem[] = [
    {
      Icon: (props) => (
        <Image src="/images/vno-highlight-defi.svg" alt="icon" {...props} />
      ),
      title: t('Largest DeFi ecosystem in Cosmos'),
      desc: (
        <Text>
          {t('largest-defi-desc-{coin}-{liquidCoin}', {
            coin: coinModeInfo.name,
            liquidCoin: coinModeInfo.liquidName,
            link: (chunks) => (
              <Link href={pathDict[coinMode].vno} passHref>
                <ChakraLink color="text-link">{chunks}</ChakraLink>
              </Link>
            ),
          })}
        </Text>
      ),
    },
    {
      Icon: (props) => (
        <Image
          src="/images/vno-highlight-liquidity.svg"
          alt="icon"
          {...props}
        />
      ),
      title: t('Never Ending Liquidity'),
      desc: t('never-ending-desc-{coin}', {
        coin: coinModeInfo.name,
      }),
    },
    {
      Icon: (props) => (
        <Image
          src="/images/vno-highlight-community.svg"
          alt="icon"
          {...props}
        />
      ),
      title: t('Thriving Community'),
      desc: (
        <Text>
          {t('Thriving-Community-desc', {
            discordLink: (chunks) => (
              <Link href={EXTERNAL_LINK.discord} passHref>
                <ChakraLink color="text-link" isExternal>
                  {chunks}
                </ChakraLink>
              </Link>
            ),
            telegramLink: (chunks) => (
              <Link href={EXTERNAL_LINK.telegramAnnouncements} passHref>
                <ChakraLink color="text-link" isExternal>
                  {chunks}
                </ChakraLink>
              </Link>
            ),
          })}
        </Text>
      ),
    },
  ];

  return coinMode === 'cro' ? [croHighlight, ...highlights] : highlights;
};

export const useVnoFeatures = () => {
  const t = useTranslations();
  const vnoFeatures: VnoHighlightItem[] = [
    {
      Icon: (props) => (
        <Image src="/images/vno-feature-utility.svg" alt="icon" {...props} />
      ),
      title: t('Utility'),
      desc: t('utility-desc'),
    },
    {
      Icon: (props) => (
        <Image src="/images/vno-feature-reward.svg" alt="icon" {...props} />
      ),
      title: t('Rewards'),
      desc: t(`Rewards-desc`),
    },
    {
      Icon: (props) => (
        <Image
          src="/images/vno-highlight-liquidity.svg"
          alt="icon"
          {...props}
        />
      ),
      title: t('Liquidity'),
      desc: t('Liquidity-desc-{link}', {
        link: (chunks) => (
          <Link href={pathDict.cro.vno} passHref>
            <ChakraLink color="text-link">{chunks}</ChakraLink>
          </Link>
        ),
      }),
    },
    {
      Icon: (props) => (
        <Image src="/images/vno-feature-alignment.svg" alt="icon" {...props} />
      ),
      title: t('Alignment'),
      desc: t('Alignment-desc'),
    },
  ];
  return vnoFeatures;
};

export type SupportedCoinInfo = {
  coinMode: CoinMode;
  title: ReactNode;
  desc: ReactNode;
  tvl: BigNumber | undefined;
  apy: number | undefined;
  networkIcon: ReactNode;
};

export const useSupportedCoinInfos = (): SupportedCoinInfo[] => {
  const cronosSdk = useSpecificVenoSDK('cronos');
  const zksyncSdk = useSpecificVenoSDK('zksync');
  const t = useTranslations();

  const { data: totalPooledCro } = useQuery({
    ...getQuery(QueryKey.TOTAL_POOLED)({
      sdk: cronosSdk,
      coinMode: 'cro',
    }),
  });

  const { data: totalPooledEth } = useQuery({
    ...getQuery(QueryKey.TOTAL_POOLED)({
      sdk: zksyncSdk,
      coinMode: 'eth',
    }),
  });

  const { data: totalPooledAtom } = useQuery({
    ...getQuery(QueryKey.TOTAL_POOLED)({
      sdk: cronosSdk,
      coinMode: 'atom',
    }),
  });

  const { data: totalPooledTia } = useQuery({
    ...getQuery(QueryKey.TOTAL_POOLED)({
      sdk: cronosSdk,
      coinMode: 'tia',
    }),
  });

  const { data: croTvlUsd } = useCoinUsdValue(totalPooledCro, 'cro');
  const { data: atomTvlUsd } = useCoinUsdValue(totalPooledAtom, 'atom');
  const { data: ethTvlUsd } = useCoinUsdValue(totalPooledEth, 'eth');
  const { data: tiaTvlUsd } = useCoinUsdValue(totalPooledTia, 'tia');

  const croApyQueryResult = useApy({
    coinMode: 'cro',
  });

  const atomApyQueryResult = useApy({
    coinMode: 'atom',
  });

  const ethApyQueryResult = useApy({
    coinMode: 'eth',
  });

  const tiaApyQueryResult = useApy({
    coinMode: 'tia',
  });

  const cronosNetworkIcon = (
    <Image
      src="/images/cronos-network.svg"
      w="48px"
      h="12px"
      alt="cronos-network"
    />
  );

  const zksyncNetworkIcon = (
    <Image
      src="/images/zksync-network.svg"
      w="53px"
      h="10px"
      alt="zksync-network"
    />
  );

  const results: (SupportedCoinInfo | null)[] = [
    {
      coinMode: 'cro',
      title: t('Stake CRO on crypto-org'),
      desc: t('support-cro-card-desc'),
      tvl: croTvlUsd,
      apy: croApyQueryResult.isLoading ? undefined : croApyQueryResult.data,
      networkIcon: cronosNetworkIcon,
    },
    {
      coinMode: 'atom',
      title: t('Earn {coin} rewards', {
        coin: COIN_MODE.atom.name,
      }),
      desc: t('support-card-desc-{coin}-{network}-{chainType}-{liquidCoin}', {
        coin: COIN_MODE.atom.name,
        liquidCoin: COIN_MODE.atom.liquidName,
        network: getNetworkConfig().chainName,
        chainType: 'CosmosHub',
      }),
      tvl: atomTvlUsd,
      apy: atomApyQueryResult.isLoading ? undefined : atomApyQueryResult.data,
      networkIcon: cronosNetworkIcon,
    },
    {
      coinMode: 'eth',
      title: t('Earn {coin} rewards', {
        coin: COIN_MODE.eth.name,
      }),
      desc: t('support-card-desc-{coin}-{network}-{chainType}-{liquidCoin}', {
        coin: COIN_MODE.eth.name,
        liquidCoin: COIN_MODE.eth.liquidName,
        network: getNetworkConfig(true).chainName,
        chainType: 'Ethereum',
      }),
      tvl: ethTvlUsd,
      apy: ethApyQueryResult.isLoading ? undefined : ethApyQueryResult.data,
      networkIcon: zksyncNetworkIcon,
    },
    {
      coinMode: 'tia',
      title: t('Earn {coin} rewards', {
        coin: COIN_MODE.tia.name,
      }),
      desc: t('support-card-desc-{coin}-{network}-{chainType}-{liquidCoin}', {
        coin: COIN_MODE.tia.name,
        liquidCoin: COIN_MODE.tia.liquidName,
        network: getNetworkConfig(false).chainName,
        chainType: 'Celestia',
      }),
      tvl: tiaTvlUsd,
      apy: tiaApyQueryResult.isLoading ? undefined : tiaApyQueryResult.data,
      networkIcon: cronosNetworkIcon,
    },
  ];

  return results.filter((v): v is SupportedCoinInfo => Boolean(v));
};

export type VenoValidator = {
  name: string;
  logo: string;
  link?: string;
  desc?: ReactNode;
};
export const venoValidators: Record<CoinMode, VenoValidator[]> = {
  eth: [
    {
      name: 'kiln',
      logo: '/images/Kiln_Logo.svg',
      link: 'https://www.kiln.fi/',
      get desc() {
        const link = this.link;
        return i18next.t('eth-validator-desc-{Link}', {
          Link: (chunks) => (
            <ChakraLink
              href={link}
              color="text-link"
              fontSize="inherit"
              isExternal
            >
              {chunks}
            </ChakraLink>
          ),
        });
      },
    },
  ],
  cro: [
    {
      name: 'Veno.finance',
      logo: '/images/text-logo-large.png',
      get desc() {
        return i18next.t('cro-validator-desc');
      },
    },
  ],
  atom: [
    {
      name: 'Melea.xyz',
      logo: 'https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/cosmos/moniker/cosmosvaloper1zqgheeawp7cmqk27dgyctd80rd8ryhqs6la9wc.png',
      link: 'https://www.mintscan.io/cosmos/validators/cosmosvaloper1zqgheeawp7cmqk27dgyctd80rd8ryhqs6la9wc',
    },
    {
      name: 'Allnodes',
      logo: 'https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/cosmos/moniker/cosmosvaloper1n229vhepft6wnkt5tjpwmxdmcnfz55jv3vp77d.png',
      link: 'https://www.mintscan.io/cosmos/validators/cosmosvaloper1n229vhepft6wnkt5tjpwmxdmcnfz55jv3vp77d',
    },
    {
      name: 'IcyCRO',
      logo: 'https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/cosmos/moniker/cosmosvaloper1ukpah0340rx7k3x2njnavwyjv6pfpvn632df9q.png',
      link: 'https://www.mintscan.io/cosmos/validators/cosmosvaloper1ukpah0340rx7k3x2njnavwyjv6pfpvn632df9q',
    },
    {
      name: 'Cros-nest',
      logo: 'https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/cosmos/moniker/cosmosvaloper1fsg635n5vgc7jazz9sx5725wnc3xqgr7awxaag.png',
      link: 'https://www.mintscan.io/cosmos/validators/cosmosvaloper1fsg635n5vgc7jazz9sx5725wnc3xqgr7awxaag',
    },
    {
      name: 'Stakin',
      logo: 'https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/cosmos/moniker/cosmosvaloper1fhr7e04ct0zslmkzqt9smakg3sxrdve6ulclj2.png',
      link: 'https://www.mintscan.io/cosmos/validators/cosmosvaloper1fhr7e04ct0zslmkzqt9smakg3sxrdve6ulclj2',
    },
    {
      name: 'Enigma',
      logo: 'https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/cosmos/moniker/cosmosvaloper1gah0q9gqypnr7ufzg0dcda3k6v8qp4ceddn8fs.png',
      link: 'https://www.mintscan.io/cosmos/validators/cosmosvaloper1gah0q9gqypnr7ufzg0dcda3k6v8qp4ceddn8fs',
    },
  ],
  tia: [
    {
      name: 'P-OPS Team',
      logo: 'https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/celestia/moniker/celestiavaloper1ac4mnwg79gyvd0x5trl2fgjv07lgfas02jf378.png',
      link: 'https://www.mintscan.io/celestia/validators/celestiavaloper1ac4mnwg79gyvd0x5trl2fgjv07lgfas02jf378',
    },
    {
      name: 'Stakin',
      logo: 'https://www.mintscan.io/assets/defaults/no-image.png',
      link: 'https://www.mintscan.io/celestia/validators/celestiavaloper1dlsl4u42ycahzjfwc6td6upgsup9tt7cz8vqm4',
    },
    {
      name: 'Allnodes',
      logo: 'https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/celestia/moniker/celestiavaloper1rcm7tth05klgkqpucdhm5hexnk49dfda3l3hak.png',
      link: 'https://www.mintscan.io/celestia/validators/celestiavaloper1rcm7tth05klgkqpucdhm5hexnk49dfda3l3hak',
    },
    {
      name: 'P2P.ORG',
      logo: 'https://www.mintscan.io/assets/defaults/no-image.png',
      link: 'https://www.mintscan.io/celestia/validators/celestiavaloper1r4kqtye4dzacmrwnh6f057p50pdjm8g59tlhhg',
    },
  ],
};
