import type { BoxProps } from '@chakra-ui/react';
import { Box, Tab, TabList, Tabs } from '@chakra-ui/react';
import { COIN_MODE, coinModes, coinModesCro } from '@ui/hooks/useCoinMode';
import { ZKSYNC_ENABLED } from '@veno-app/wallet';
import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import type { Swiper as SwiperClass } from 'swiper/types';

import { SupportedCoinCard } from './SupportedCoinCard';
import { useSupportedCoinInfos } from './utils';

export const SupportedCardSwiper = ({ ...props }: BoxProps) => {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const supportedCoinInfos = useSupportedCoinInfos();
  const [index, setIndex] = useState(0);

  return (
    <Box {...props}>
      <Tabs
        index={index}
        onChange={(index) => {
          setIndex(index);
          try {
            swiper?.slideTo(index);
          } catch (e) {
            // do nothing
          }
        }}
      >
        <TabList>
          {(ZKSYNC_ENABLED ? coinModes : coinModesCro).map((coinMode) => {
            return <Tab key={coinMode}>{COIN_MODE[coinMode].name}</Tab>;
          })}
        </TabList>
      </Tabs>
      <Box>
        <Swiper
          onSwiper={setSwiper}
          grabCursor={false}
          slideToClickedSlide={false}
          loop={false}
          onSlideChange={({ realIndex }) => {
            setIndex(realIndex);
          }}
        >
          {supportedCoinInfos.map((item, i) => {
            return (
              <SwiperSlide key={i}>
                <SupportedCoinCard
                  {...item}
                  height="calc(100% - 20px)"
                  mt="24px"
                  mb="8px"
                  mx="32px"
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>
    </Box>
  );
};
