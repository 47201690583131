import type { BoxProps } from '@chakra-ui/react';
import {
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import { Global } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import { getDesktopBgImage } from '@ui/components/DesktopBg';
import { useIsDesktop } from '@ui/components/MobileOrDesktop';
import { RotatingBg } from '@ui/components/RotatingBg';
import { pathDict } from '@ui/config/paths';
import { useApy } from '@ui/hooks/useApr';
import { COIN_MODE } from '@ui/hooks/useCoinMode';
import useCoinPerStakedCoin from '@ui/hooks/useCoinPerStakedCoin';
import { useCoinUsdValue } from '@ui/hooks/useCoinUsdValue';
import { useTranslations } from '@ui/i18n';
import Link from '@ui/overrides/next/link';
import { useSpecificVenoSDK } from '@ui/providers/VenoSDKProvider';
import { getQuery } from '@ui/queries/queries';
import { QueryKey } from '@ui/queries/queryKey';
import { formatTokenAmount } from '@ui/utils/format';
import { ArrowBack } from '@veno-app/icons';
import { chunk } from 'lodash-es';
import NextImage from 'next/image';
import type { ReactNode } from 'react';
import { Children, isValidElement, useEffect } from 'react';

import { getVenoHighlights } from '../getVenoHighlights';
import Pattern2 from '../images/pattern-2-desktop-landing.png';
import { venoValidators } from '../utils';
import { VenoHighlightCard } from '../VenoHighlightCard';
import { useSupportedCoinDrawer } from './useSupportedCoinDrawer';
import { ValidatorCard } from './ValidatorCard';

export * from './SupportedCoinDrawer';
export * from './useSupportedCoinDrawer';

export const SupportedCoinPage: React.FC = (props: BoxProps) => {
  const t = useTranslations();
  const coinMode = useSupportedCoinDrawer((s) => s.coinMode);
  const isOpen = useSupportedCoinDrawer((s) => s.isOpen);
  const onClose = useSupportedCoinDrawer((s) => s.onClose);
  const sdk = useSpecificVenoSDK(COIN_MODE[coinMode].chainType);
  const coinModeInfo = COIN_MODE[coinMode];
  const coinPathDict = pathDict[coinMode];
  const isDesktop = useIsDesktop();

  const totalPooledQueryResult = useQuery({
    ...getQuery(QueryKey.TOTAL_POOLED)({
      sdk,
      coinMode,
    }),
  });

  const totalPooledUsdQueryResult = useCoinUsdValue(
    totalPooledQueryResult.data,
    coinMode,
  );

  const apyQueryResult = useApy();

  const exchangeRatioQueryResult = useCoinPerStakedCoin(coinMode);

  useEffect(() => {
    if (isDesktop && isOpen) {
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      {coinMode === 'atom' ? atomBg : coinMode === 'eth' ? ethBg : croBg}
      <Box pt="32px" pb="200px" position="relative" {...props}>
        <Button
          variant="link"
          position="absolute"
          left="0px"
          top="40px"
          color="text-link"
          onClick={onClose}
        >
          <Icon as={ArrowBack} boxSize="14px" mr="6px" />
          {t('Back')}
        </Button>
        <Box
          shadow="card.active"
          w="150px"
          h="150px"
          rounded="full"
          p="5px"
          mx="auto"
        >
          <RotatingBg w="100%" h="100%" align="center" justify="center">
            <coinModeInfo.Icon w="120px" h="120px" />
          </RotatingBg>
        </Box>
        <Text as="h1" textStyle="h1" textAlign="center" mt="12px">
          {t('{coin} staking', {
            coin: coinModeInfo.name,
          })}
        </Text>
        <Flex mt="32px" gap="150px" justify="center">
          <InfoBox
            title={t('Total value locked')}
            content={
              totalPooledQueryResult.data && (
                <Flex align="center" gap="4px">
                  <coinModeInfo.Icon w="32px" h="32px" />
                  <Text>
                    {formatTokenAmount(
                      totalPooledQueryResult.data,
                      2,
                      coinModeInfo.decimals,
                    )}
                  </Text>
                </Flex>
              )
            }
            showFooter={!!totalPooledUsdQueryResult.data}
            footer={'$' + formatTokenAmount(totalPooledUsdQueryResult.data, 2)}
          />
          <InfoBox
            title={t('Staking APY')}
            content={
              apyQueryResult.data === 0
                ? '--'
                : apyQueryResult.data &&
                  `${(apyQueryResult.data * 100).toFixed(2)}%`
            }
          />
          <InfoBox
            title={t('You will receive')}
            content={
              exchangeRatioQueryResult.data &&
              `1 ${
                coinModeInfo.liquidName
              } = ${exchangeRatioQueryResult.data.toFixed(3)} ${
                coinModeInfo.name
              }`
            }
          />
        </Flex>
        <Link href={coinPathDict.stake} passHref>
          <Button as="a" size="lg" w="340px" mx="auto" display="flex" mt="48px">
            {t('Stake {coin}', {
              coin: coinModeInfo.name,
            })}
          </Button>
        </Link>
        <Text textStyle="h1" mt="62px" textAlign="center">
          {t('Why Veno?')}
        </Text>
        <Text textStyle="h3" mt="16px" textAlign="center">
          {coinMode === 'cro' &&
            t(
              'Veno is the largest and most trusted protocol to liquid stake CRO',
            )}
          {coinMode === 'atom' &&
            t(
              'Veno offers access to the full Cronos ecosystem to use your {coin} on',
              {
                coin: coinModeInfo.liquidName,
              },
            )}
          {coinMode === 'eth' &&
            t('Liquid Stake {coin} natively on {platform}', {
              coin: 'ETH',
              platform: 'ZkSync',
            })}
        </Text>
        <Flex justify="center" mt="48px">
          <DividerListing>
            {getVenoHighlights(t, coinMode).map((item, i) => {
              return (
                <VenoHighlightCard
                  key={i}
                  {...item}
                  w="370px"
                  my="8px"
                  shadow="unset"
                  bg="transparent"
                />
              );
            })}
          </DividerListing>
        </Flex>
        <Text textStyle="h1" textAlign="center" mt="240px">
          {t('Our Validators')}
        </Text>
        <Flex mt="48px" flexDir="column" alignItems="center">
          {venoValidators[coinMode].length > 1 ? (
            <>
              <Text textStyle="h3">
                {t('{coin}-validator-desc', {
                  coin: coinModeInfo.name,
                })}
              </Text>
              <Flex flexWrap="wrap" gap="16px" mt="48px" justify="center">
                {venoValidators[coinMode].map((item, i) => {
                  return (
                    <Box key={i}>
                      <ValidatorCard
                        {...item}
                        w="260px"
                        h="150px"
                        mx="0"
                        flexShrink="0"
                      />
                      <Text textStyle="h4Bold" textAlign="center">
                        {item.name}
                      </Text>
                    </Box>
                  );
                })}
              </Flex>
            </>
          ) : (
            <Flex gap="48px" w="780px">
              <ValidatorCard
                {...venoValidators[coinMode][0]}
                w="260px"
                h="150px"
                mx="0"
                flexShrink="0"
              />
              <Text textStyle="h3">{venoValidators[coinMode][0].desc}</Text>
            </Flex>
          )}
        </Flex>
      </Box>
    </>
  );
};
const croBg = (
  <>
    <Box
      left={0}
      top="200px"
      zIndex={-1}
      position="absolute"
      w="full"
      h="800px"
      {...getDesktopBgImage('PATTERN_3')}
    />
    <Box
      zIndex="-1"
      position="absolute"
      top="1200px"
      right="0px"
      overflow="hidden"
    >
      <Box position="relative" right="-100px">
        <NextImage
          width="703"
          height="703"
          alt="Pattern 4"
          src={Pattern2}
          style={{
            maxWidth: '100%',
            height: 'auto',
          }}
        />
      </Box>
    </Box>
  </>
);

const atomBg = (
  <Global
    styles={{
      body: {
        backgroundImage: 'url(/images/atom-landing-bg-desktop.svg)',
        backgroundSize: '100vw auto',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '0px 100px',
      },
    }}
  />
);

const ethBg = (
  <Global
    styles={{
      body: {
        backgroundImage: 'url(/images/eth-landing-bg-desktop.svg)',
        backgroundSize: '100vw auto',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '0px 100px',
      },
    }}
  />
);

const InfoBox = ({
  title,
  content,
  footer,
  showFooter,
}: {
  title: ReactNode;
  content: ReactNode;
  showFooter?: boolean;
  footer?: ReactNode;
}) => {
  return (
    <Flex gap="6px" alignItems="center" textAlign="center" direction="column">
      <Box textStyle="h3">{title}</Box>
      <Skeleton isLoaded={!!content}>
        <Box textStyle="h3Bold">{content ?? '-------'}</Box>
      </Skeleton>
      {showFooter ? (
        <Skeleton isLoaded={!!footer}>
          <Box textStyle="h4" color="text-light">
            {footer ?? '-------'}
          </Box>
        </Skeleton>
      ) : null}
    </Flex>
  );
};

const DividerListing = ({ children }: { children: JSX.Element[] }) => {
  const childrenList = Children.map(children, (child) => {
    return isValidElement(child) ? child : null;
  }).filter((v) => v);
  const rows =
    Children.count(children) > 3 ? chunk(childrenList, 2) : [childrenList];

  return (
    <Box>
      {rows.map((cols, i) => {
        return (
          <Flex key={i}>
            {cols.map((child, k) => {
              const hasTopDivider = i > 0;
              const hasLeftDivider = k > 0;
              return (
                <Flex key={k} justify="center" position="relative">
                  {hasTopDivider && (
                    <Divider
                      orientation="horizontal"
                      w="70%"
                      position="absolute"
                      top="0"
                      left="15%"
                    />
                  )}
                  {hasLeftDivider && (
                    <Divider
                      orientation="vertical"
                      h="70%"
                      position="absolute"
                      left="0"
                      top="15%"
                    />
                  )}
                  {child}
                </Flex>
              );
            })}
          </Flex>
        );
      })}
    </Box>
  );
};
