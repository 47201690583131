import { EXTERNAL_LINK } from '@ui/config/externalLinks';
import { languages } from '@ui/i18n';
import { useRouter } from '@ui/overrides/next/router';
import { merge } from 'lodash-es';
import { NextSeo } from 'next-seo';
import type { NextSeoProps } from 'next-seo/lib/types';

const SITE_NAME = 'veno.finance';
const AUTHOR = EXTERNAL_LINK.twitter;
const BASE_URL = 'https://veno.finance';

type SEOTemplateProps = {
  title: string;
  locale?: string;
  path?: string;
  description: string;
  moreConfig?: NextSeoProps;
  textLimit?: number;
};

const defaultSEOConfig: NextSeoProps = {
  noindex: false,
  nofollow: false,
  openGraph: {
    site_name: SITE_NAME,
    type: 'website',
    article: {
      authors: [AUTHOR],
    },
    images: [
      {
        url: 'https://veno.finance/images/text-logo-large.png',
        width: 800,
        height: 196,
        alt: SITE_NAME,
      },
    ],
  },
  twitter: {
    handle: AUTHOR,
    site: AUTHOR,
    cardType: 'summary',
  },
};

export const SEOTemplate = ({
  locale,
  path,
  title,
  description,
  moreConfig = {},
  textLimit = 300,
}: SEOTemplateProps) => {
  const { asPath } = useRouter();
  // Remove the query in asPath
  path = path ?? asPath.split('?')[0];
  const url = `${BASE_URL}${path}`;

  const languageAlternates = [
    { hrefLang: 'x-default', href: url },
    ...languages.map((l) => ({
      hrefLang: l.lang,
      href: `${BASE_URL}/${l.lang}${path}`,
    })),
  ];

  description =
    description.length > textLimit
      ? description.slice(0, textLimit) + '...'
      : description;

  const customConfig: NextSeoProps = {
    title,
    titleTemplate: title,
    description,
    canonical: url,
    languageAlternates,
    openGraph: {
      locale,
      url,
      title: title,
      description,
    },
  };
  const seoConfig = merge({}, defaultSEOConfig, customConfig, moreConfig);
  return <NextSeo {...seoConfig} />;
};
