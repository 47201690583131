import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import { getDesktopBgImage } from '@ui/components/DesktopBg';
import { TokenIcon } from '@ui/components/TokenIcon';
import UpdateBanners from '@ui/components/UpdateBanner/UpdateBanners';
import { EXTERNAL_LINK } from '@ui/config/externalLinks';
import { useTranslations } from '@ui/i18n';
import { formatNumber, formatTokenAmount } from '@ui/utils/format';
import { Discord, Medium, Twitter } from '@veno-app/icons';
import NextImage from 'next/image';

import AuditCard from './AuditCard';
import {
  EcosystemSupportCard,
  EcosystemSupportList,
} from './EcosystemSupportCard';
import Pattern2 from './images/pattern-2-desktop-landing.png';
import Pattern4 from './images/pattern-4-desktop-landing.png';
import { SupportedCoinCard } from './SupportedCoinCard';
import { SupportedCoinPage, useSupportedCoinDrawer } from './SupportedCoinPage';
import { HOW_IT_WORKS_BUTTONS } from './types';
import { useHowItWorksData, useLandingPage } from './useLandingPage';
import { useSupportedCoinInfos, useVnoFeatures } from './utils';
import { VenoHighlightCard } from './VenoHighlightCard';

export const LandingPageDesktop = () => {
  const t = useTranslations();
  const {
    tvlUsd,
    tvlUsdLoading,
    getButtonShadow,
    getButtonAction,
    activeMockup,
    totalRewardPaid,
    stakerCount,
  } = useLandingPage();
  const supportedCoinInfos = useSupportedCoinInfos();
  const isSupportedCoinDrawerOpen = useSupportedCoinDrawer((s) => s.isOpen);
  const HOW_IT_WORKS = useHowItWorksData();
  const vnoFeatures = useVnoFeatures();

  if (isSupportedCoinDrawerOpen) {
    return <SupportedCoinPage />;
  }

  return (
    <>
      <Box
        left={0}
        top="500px"
        zIndex={-1}
        position="absolute"
        w="full"
        h="800px"
        {...getDesktopBgImage('PATTERN_3')}
      />

      <Box>
        <UpdateBanners minH="154px" mt="32px" />
        <Flex flexDir="column" align="center" pt="49px" width="full" gap="16px">
          <Box>
            <Image
              role="heading"
              aria-label="VENO"
              aria-level={1}
              alt="VENO"
              src="/images/text-logo-large.png"
              w="400px"
              h="98px"
            />
          </Box>
          <Box textAlign="center">
            <Text as="h2" fontSize="48px" lineHeight="63px">
              {t('Simple and secure liquid staking')}
            </Text>
            <Text as="h2" textStyle="h2" color="text.light">
              {t(
                'Earn more rewards with Veno! Boost extra APY with our ecosystem partners!',
              )}
            </Text>
          </Box>
        </Flex>
        <Flex justify="center" gap="64px" mt="56px">
          <Flex flexDir="column">
            <Text textStyle="h3">{t('Total value locked')}</Text>
            {tvlUsdLoading ? (
              <Skeleton w="100px" h="31px" />
            ) : (
              <Text textStyle="h3Bold" color="text.active">
                {tvlUsd}
              </Text>
            )}
          </Flex>
          <Flex flexDir="column">
            <Text textStyle="h3">{t('Total Rewards Paid')}</Text>
            <Skeleton isLoaded={!!totalRewardPaid}>
              <Text textStyle="h3Bold" color="text.active">
                +{formatTokenAmount(totalRewardPaid, 0)}$
              </Text>
            </Skeleton>
          </Flex>
          <Flex flexDir="column">
            <Text textStyle="h3">{t('Stakers')}</Text>
            <Skeleton isLoaded={!!stakerCount}>
              <Text textStyle="h3Bold" color="text.active">
                +{formatNumber(stakerCount, 0) || '------'}
              </Text>
            </Skeleton>
          </Flex>
        </Flex>
        <Text textStyle="h1" textAlign="center" mt="188px">
          {t('Supported Coins')}
        </Text>
        <Flex mt="40px" gap="16px" justify="center">
          {supportedCoinInfos.map((item, i) => {
            return <SupportedCoinCard key={i} w="400px" {...item} />;
          })}
        </Flex>
        <Flex mt="184px" ml="141px" gap="48px" mr="120px">
          {HOW_IT_WORKS_BUTTONS.map((key) => {
            return (
              <Box key={key} hidden={key !== activeMockup}>
                <NextImage
                  alt={`${key} mockup`}
                  src={HOW_IT_WORKS[key].mockup}
                  width="318"
                  height="631"
                  placeholder="blur"
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
              </Box>
            );
          })}
          <Box>
            <Text textStyle="h1" mb={12}>
              {t('How Veno works')}
            </Text>
            <Flex gap={4}>
              <Button
                w="120px"
                variant="card"
                color="#000000"
                shadow={getButtonShadow('STAKE')}
                onClick={getButtonAction('STAKE')}
                aria-label="stake button"
              >
                {t('Stake')}
              </Button>
              <Button
                w="120px"
                variant="card"
                color="#000000"
                shadow={getButtonShadow('LCRO')}
                onClick={getButtonAction('LCRO')}
                aria-label="lcro button"
              >
                {t('Liquid token')}
              </Button>
              <Button
                w="120px"
                variant="card"
                color="#000000"
                shadow={getButtonShadow('UNSTAKE')}
                onClick={getButtonAction('UNSTAKE')}
                aria-label="unstake button"
              >
                {t('Unstake')}
              </Button>
            </Flex>
            <Text pt={22} textStyle="h2" maxW="500px">
              {HOW_IT_WORKS[activeMockup].description}
            </Text>
          </Box>
        </Flex>
        <Flex
          mt="188px"
          maxW="868px"
          mx="auto"
          gap="56px"
          flexDir="column"
          position="relative"
        >
          <Box
            zIndex="-1"
            position="absolute"
            ml="-220px"
            mt="-50px"
            top="-500px"
          >
            <NextImage
              width="703"
              height="703"
              alt="Pattern 4"
              src={Pattern2}
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          </Box>
          <Box
            zIndex="-1"
            position="absolute"
            ml="-220px"
            mt="-50px"
            bottom="-600px"
          >
            <NextImage
              width="703"
              height="703"
              alt="Pattern 4"
              src={Pattern4}
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          </Box>
          <Flex direction="column" alignItems="center">
            <Text textStyle="h1" textAlign="center">
              {t('Our protocol token VNO')}
            </Text>
            <TokenIcon token="vno" w="160px" h="160px" mt="10px" />
            <Button
              as="a"
              variant="secondary"
              href={EXTERNAL_LINK.whitepaper}
              target="_blank"
              aria-label="link to VNO Tokenomics"
              mt="10px"
            >
              VNO Tokenomics
            </Button>
            <Text textStyle="h2" textAlign="center" mt="30px">
              {t('VNO is our protocol token, providing:')}
            </Text>
            <Flex gap="16px" w="820px" flexWrap="wrap" mt="48px">
              {vnoFeatures.map((item, i) => {
                return <VenoHighlightCard key={i} {...item} w="400px" />;
              })}
            </Flex>
          </Flex>
        </Flex>
        <Box mt="188px">
          <Text textStyle="h1" textAlign="center">
            {t('Ecosystem Support')}
          </Text>
          <Text textStyle="h2" textAlign="center" mt="48px">
            {t('Veno is supported by the Cronos ecosystem')}
          </Text>
          <Flex
            maxWidth="800px"
            gap="10px"
            rowGap="40px"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="center"
            mt="48px"
            mx="auto"
          >
            {EcosystemSupportList.map((item, i) => {
              return (
                <Flex
                  key={i}
                  width="30%"
                  alignItems="center"
                  justifyContent="center"
                >
                  <EcosystemSupportCard {...item} />
                </Flex>
              );
            })}
          </Flex>
        </Box>
        <Box mt="205px" ml="141px">
          <Text textStyle="h1">{t('Is Veno secure?')}</Text>
          <Text pt={2} textStyle="h3" maxW="843px" mb={11}>
            {t('veno-secure-content')}
          </Text>
          <Flex gap="180px">
            <Box w="343px">
              <Text textStyle="h3">{t('Audits')}</Text>
              <AuditCard />
            </Box>
            <Box flex={1} mb="100px">
              <Text textStyle="h3">{t('Join the community')}</Text>
              <Flex mt={6} gap="26px">
                <a
                  href={EXTERNAL_LINK.twitter}
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label="Twitter link"
                >
                  <Icon as={Twitter} h="24px" w="24px" color="bg.button" />
                </a>
                <a
                  href={EXTERNAL_LINK.medium}
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label="Medium link"
                >
                  <Icon as={Medium} h="24px" w="24px" color="bg.button" />
                </a>
                <a
                  href={EXTERNAL_LINK.discord}
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label="Discord link"
                >
                  <Icon as={Discord} h="24px" w="24px" color="bg.button" />
                </a>
              </Flex>
              <Flex mt={8} pb={8}>
                <Button
                  as="a"
                  variant="secondary"
                  aria-label="link to gitbook"
                  target="_blank"
                  href={EXTERNAL_LINK.gitbook}
                >
                  {t('Documentation')}
                </Button>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Box>
    </>
  );
};
