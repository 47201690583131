import { Box } from '@chakra-ui/react';
import type { CardProps } from '@ui/components/Card';
import { Card } from '@ui/components/Card';
import { RotatingBg } from '@ui/components/RotatingBg';

import type { VnoHighlightItem } from './utils';

export type VenoHighlightCardProps = Omit<CardProps, 'title'> &
  VnoHighlightItem;
export const VenoHighlightCard = ({
  Icon,
  title,
  desc,
  ...props
}: VenoHighlightCardProps) => {
  return (
    <Card m="0" textAlign="center" py="18px" px="16px" {...props}>
      <Box
        shadow="card.active"
        w="56px"
        h="56px"
        rounded="full"
        p="1px"
        mx="auto"
      >
        <RotatingBg w="100%" h="100%" align="center" justify="center">
          <Icon w="48px" h="48px" />
        </RotatingBg>
      </Box>
      <Box
        textStyle={{
          base: 'bodyBold',
          desktop: 'h4Bold',
        }}
        mt="18px"
      >
        {title}
      </Box>
      <Box
        mt={{
          base: '4px',
          desktop: '12px',
        }}
        textStyle={{
          desktop: 'h4',
        }}
      >
        {desc}
      </Box>
    </Card>
  );
};
