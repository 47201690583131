import { Link as ChakraLink } from '@chakra-ui/react';
import type { CardProps } from '@ui/components/Card';
import { Card } from '@ui/components/Card';

import type { VenoValidator } from '../utils';

export type ValidatorCardProps = CardProps & VenoValidator;
export const ValidatorCard = ({ logo, link, ...props }: ValidatorCardProps) => {
  return (
    <Card
      variant={link ? 'button' : undefined}
      bgImage={logo}
      bgSize="contain"
      bgPos="center center"
      bgRepeat="no-repeat"
      cursor={link ? 'pointer' : undefined}
      position="relative"
      padding="20px"
      sx={{
        backgroundOrigin: 'content-box',
      }}
      {...props}
    >
      {link && (
        <ChakraLink
          display="block"
          w="full"
          h="full"
          href={link}
          isExternal
          position="absolute"
        />
      )}
    </Card>
  );
};
