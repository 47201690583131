import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  keyframes,
  Skeleton,
  Tab,
  TabList,
  Tabs,
  Text,
} from '@chakra-ui/react';
import VenoToken from '@public/tokens/vno.svg';
import { HorizontalMarqueeBox } from '@ui/components/HorizontalMarqueeBox';
import StakingInfoDrawer from '@ui/components/StakingInfoDrawer';
import { SwiperSlide, SwiperWithScale } from '@ui/components/Swiper';
import UpdateBanners from '@ui/components/UpdateBanner/UpdateBanners';
import { EXTERNAL_LINK } from '@ui/config/externalLinks';
import { useTranslations } from '@ui/i18n';
import { formatNumber, formatTokenAmount } from '@ui/utils/format';
import getBgImage from '@ui/utils/getBgImageProps';
import { Discord, Medium, Twitter } from '@veno-app/icons';
import { capitalize } from 'lodash-es';
import NextImage from 'next/image';
import { useState } from 'react';

import AuditCard from './AuditCard';
import {
  EcosystemSupportCard,
  EcosystemSupportList,
} from './EcosystemSupportCard';
import { HowItWorksTextSwiper } from './HowItWorksTextSwiper';
import { SupportedCardSwiper } from './SupportedCardSwiper';
import { HOW_IT_WORKS_BUTTONS } from './types';
import { useLandingPage } from './useLandingPage';
import { useVnoFeatures } from './utils';
import { VenoHighlightCard } from './VenoHighlightCard';

export const LandingPage = () => {
  const t = useTranslations();
  const [stakingInfoDrawerOpen, setStakingInfoDrawerOpen] = useState(false);
  const {
    tvlUsd,
    tvlUsdLoading,
    setActiveMockup,
    apy,
    activeMockup,
    grossApy,
    totalRewardPaid,
    stakerCount,
  } = useLandingPage();
  const vnoFeatures = useVnoFeatures();

  return (
    <Box
      color="#17181A"
      {...getBgImage('PATTERN_2')}
      backgroundPosition="100% 400px"
    >
      <StakingInfoDrawer
        netApy={`${(apy * 100).toFixed(2)}%`}
        grossApy={`${((grossApy ?? 0) * 100).toFixed(2)}%`}
        isOpen={stakingInfoDrawerOpen}
        close={() => setStakingInfoDrawerOpen(false)}
      />
      <Box
        px={4}
        bgImage="linear-gradient(180deg, #EBF0F3 0%, rgba(235, 240, 243, 0) 40%),linear-gradient(180deg, rgba(235, 240, 243, 0) 0%, #EBF0F3 50%)"
        bgPosition="left top, 0px 180px"
        backgroundSize="100% 330px, 100% 300px"
        backgroundRepeat="no-repeat"
        position="relative"
        overflowX="hidden"
      >
        <UpdateBanners />
        <Box
          width="100%"
          height="470px"
          bgImage="url('/images/landing-bg-top-mobile.svg')"
          bgPosition="center 13.28%"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          position="absolute"
          top="0"
          left="0"
          zIndex={-1}
        />
        <Flex
          position="relative"
          flexDir="column"
          pt="182px"
          width="full"
          gap="16px"
        >
          <Image
            role="heading"
            aria-label="VENO"
            aria-level={1}
            alt="VENO"
            src="/images/text-logo.svg"
            w="250px"
            h="61px"
          />
          <Box>
            <Text as="h2" textStyle="h3">
              {t('Simple and secure liquid staking')}
            </Text>
            <Text as="h2" textStyle="h4" mt={2}>
              {t(
                'Earn more rewards with Veno! Boost extra APY with our ecosystem partners!',
              )}
            </Text>
          </Box>
        </Flex>
        <Flex
          position="relative"
          w="full"
          flexWrap="wrap"
          gap={8}
          mt={8}
          justifyContent="center"
        >
          <Flex flexDir="column" alignItems="center" flexBasis="40%">
            <Text textStyle="body">{t('Total value locked')}</Text>
            {tvlUsdLoading ? (
              <Skeleton w="100px" h="19px" />
            ) : (
              <Text
                textStyle="h3Bold"
                lineHeight="19px"
                fontWeight="bold"
                color="text.active"
              >
                {tvlUsd}
              </Text>
            )}
          </Flex>

          <Flex flexDir="column" alignItems="center" flexBasis="40%">
            <Text textStyle="body">{t('Total Rewards Paid')}</Text>
            <Skeleton isLoaded={!!totalRewardPaid}>
              <Text
                textStyle="h3Bold"
                color="text.active"
                lineHeight="19px"
                fontWeight="bold"
              >
                +{formatTokenAmount(totalRewardPaid, 0)}$
              </Text>
            </Skeleton>
          </Flex>

          <Flex flexDir="column" alignItems="center" flexBasis="40%">
            <Text textStyle="body">{t('Stakers')}</Text>
            <Skeleton isLoaded={!!stakerCount}>
              <Text
                textStyle="h3Bold"
                color="text.active"
                lineHeight="19px"
                fontWeight="bold"
              >
                +{formatNumber(stakerCount, 0) || '------'}
              </Text>
            </Skeleton>
          </Flex>
        </Flex>
        <Text textStyle="h3Bold" mt="72px">
          {t('Supported Coins') + ' & ' + t('Network')}
        </Text>
        <SupportedCardSwiper mx="-16px" mt="24px" />
        <Box mt="72px" mx="-16px" px="16px" position="relative">
          <Box
            width="100vw"
            height="100%"
            overflowX="hidden"
            position="absolute"
            left="0"
            top="0"
            zIndex="-1"
          >
            <Box
              bgImage="/images/iphone-bg-landing.png"
              bgSize="100% 100%"
              height="500px"
              position="absolute"
              left="-75px"
              right="-57px"
              top="220px"
              filter="blur(80px)"
              animation={`${spin} infinite 5s linear`}
            />
          </Box>
          <Text textStyle="h3Bold" mb={6}>
            {t('How Veno works')}
          </Text>
          <Tabs
            index={HOW_IT_WORKS_BUTTONS.indexOf(activeMockup)}
            onChange={(index) => {
              setActiveMockup(HOW_IT_WORKS_BUTTONS[index]);
            }}
            mx="-16px"
          >
            <TabList>
              {HOW_IT_WORKS_BUTTONS.map((key) => {
                return (
                  <Tab key={key} width={key === 'LCRO' ? '20%' : '0'}>
                    {key === 'LCRO' ? 'Liquid token' : capitalize(key)}
                  </Tab>
                );
              })}
            </TabList>
          </Tabs>
          <HowItWorksTextSwiper
            pt={8}
            activeMockup={activeMockup}
            onMockupChange={setActiveMockup}
          />
          <Box
            pt="100px"
            mx="-16px"
            px="16px"
            {...getBgImage('PATTERN_4')}
            backgroundPosition="-200px 50px"
          >
            <Text textStyle="h3Bold">{t('Our protocol token VNO')}</Text>
            <Flex mt="16px" justify="center" gap="32px">
              <NextImage
                alt="VENO"
                src={VenoToken}
                width="133"
                height="133"
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            </Flex>
            <Flex align="center" mt={4}>
              <Button
                mx="auto"
                as="a"
                variant="secondary"
                href={EXTERNAL_LINK.whitepaper}
                target="_blank"
                aria-label="link to VNO Tokenomics"
              >
                VNO Tokenomics
              </Button>
            </Flex>
            <Text pt={4} textStyle="body">
              {t('VNO is our protocol token, providing:')}
            </Text>
            <Box mx="-16px" mt="20px">
              <SwiperWithScale scaleEnabled={false} px="16px">
                {vnoFeatures.map((item, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <VenoHighlightCard
                        {...item}
                        height="calc(100% - 20px)"
                        my="8px"
                      />
                    </SwiperSlide>
                  );
                })}
              </SwiperWithScale>
            </Box>
          </Box>
        </Box>
        <Box mt="64px">
          <Text textStyle="h3Bold">{t('Ecosystem Support')}</Text>
          <Text mt="16px">
            {t('Veno is supported by the Cronos ecosystem')}
          </Text>
          <Box mx="-16px" mt="20px">
            <HorizontalMarqueeBox duration={16}>
              {EcosystemSupportList.map((item, i) => {
                return (
                  <Flex key={i} height="90px" alignItems="center" mr="10px">
                    <EcosystemSupportCard {...item} />
                  </Flex>
                );
              })}
            </HorizontalMarqueeBox>
          </Box>
        </Box>
        <Text pt="64px" textStyle="h3Bold">
          {t('Is Veno secure?')}
        </Text>
        <Text pt={4} textStyle="body" maxW="343px">
          {t('veno-secure-content')}
        </Text>
      </Box>
      <Box px={4} mt="24px">
        <Text textStyle="h4">{t('Audits')}</Text>
        <AuditCard />
        <Text textStyle="h4" pt={8}>
          {t('Join the community')}
        </Text>
        <Flex mt={6} gap="26px">
          <a
            href={EXTERNAL_LINK.twitter}
            rel="noopener noreferrer"
            target="_blank"
            aria-label="twitter link"
          >
            <Icon as={Twitter} h="24px" w="24px" color="bg.button" />
          </a>
          <a
            href={EXTERNAL_LINK.medium}
            rel="noopener noreferrer"
            target="_blank"
            aria-label="medium link"
          >
            <Icon as={Medium} h="24px" w="24px" color="bg.button" />
          </a>
          <a
            href={EXTERNAL_LINK.discord}
            rel="noopener noreferrer"
            target="_blank"
            aria-label="discord link"
          >
            <Icon as={Discord} h="24px" w="24px" color="bg.button" />
          </a>
        </Flex>
        <Flex mt={8} pb={8}>
          <Button
            as="a"
            variant="secondary"
            py="6px"
            px="16px"
            aria-label="link to gitbook"
            target="_blank"
            href={EXTERNAL_LINK.gitbook}
          >
            {t('Documentation')}
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
