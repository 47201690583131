import type { BoxProps } from '@chakra-ui/react';
import { Box, Flex, Text } from '@chakra-ui/react';
import NextImage from 'next/image';
import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import type { Swiper as SwiperClass } from 'swiper/types';

import type { ActiveMockup } from './types';
import { HOW_IT_WORKS_BUTTONS } from './types';
import { useHowItWorksData } from './useLandingPage';

export const HowItWorksTextSwiper = ({
  activeMockup,
  onMockupChange,
  ...props
}: {
  activeMockup: ActiveMockup;
  onMockupChange: (activeMockup: ActiveMockup) => void;
} & BoxProps) => {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const HOW_IT_WORKS = useHowItWorksData();

  useEffect(() => {
    try {
      swiper?.slideTo(HOW_IT_WORKS_BUTTONS.indexOf(activeMockup));
    } catch (e) {
      // do nothing
    }
  }, [activeMockup, swiper]);

  return (
    <Box {...props}>
      <Swiper
        onSwiper={setSwiper}
        grabCursor={false}
        slideToClickedSlide={false}
        loop={false}
        onSlideChange={({ realIndex }) => {
          onMockupChange(HOW_IT_WORKS_BUTTONS[realIndex]);
        }}
      >
        {HOW_IT_WORKS_BUTTONS.map((type, i) => {
          return (
            <SwiperSlide key={i}>
              <Text minH="96px">{HOW_IT_WORKS[type].description}</Text>
              <Flex justify="center" mt={6}>
                <NextImage
                  alt="mockup"
                  src={HOW_IT_WORKS[type].mockup}
                  width="251"
                  height="500"
                  placeholder="blur"
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
              </Flex>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Box>
  );
};
