import {
  InDesktop,
  InMobile,
  useIsDesktop,
} from '@ui/components/MobileOrDesktop';
import { SEOTemplate } from '@ui/components/SEO';
import UpdateBannerContentfulProvider from '@ui/components/UpdateBanner/UpdateBannerContentfulProvider';
import { useContentfulBanners } from '@ui/queries/contentfulBanners';
import type { NextPage } from 'next';
import dynamic from 'next/dynamic';

import { LandingPage } from './LandingPage';
import { LandingPageDesktop } from './LandingPageDesktop';
import { useSupportedCoinDrawer } from './SupportedCoinPage';

const LazySupportedCoinDrawer = dynamic(
  () => import('./SupportedCoinPage').then((mod) => mod.SupportedCoinDrawer),
  { ssr: false },
);

export const Landing: NextPage = () => {
  const isDesktop = useIsDesktop();
  const banners = useContentfulBanners() ?? [];
  const isSupportedCoinDrawerOpen = useSupportedCoinDrawer((s) => s.isOpen);

  return (
    <>
      <SEOTemplate
        title="Veno Finance - Simple and secure liquid staking."
        description="Stake CRO on Veno Finance and earn the highest APY with never ending liquidity and additional DeFi rewards."
      />
      {isSupportedCoinDrawerOpen && !isDesktop && <LazySupportedCoinDrawer />}
      <UpdateBannerContentfulProvider banners={banners}>
        <InMobile>
          <LandingPage />
        </InMobile>
        <InDesktop>
          <LandingPageDesktop />
        </InDesktop>
      </UpdateBannerContentfulProvider>
    </>
  );
};
