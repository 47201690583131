import type { BoxProps } from '@chakra-ui/react';
import { chakra, Flex, shouldForwardProp } from '@chakra-ui/react';
import { isValidMotionProp, motion } from 'framer-motion';
import type { ComponentProps } from 'react';

const ChakraMotionBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

type ChakraMotionBoxProps = ComponentProps<typeof ChakraMotionBox>;

export type HorizontalMarqueeBoxProps = BoxProps & {
  /**
   * in seconds
   */
  duration: number;
};
export const HorizontalMarqueeBox = ({
  children,
  duration,
  ...props
}: HorizontalMarqueeBoxProps) => {
  const commonMotionBoxProps: ChakraMotionBoxProps = {
    display: 'flex',
    flexWrap: 'nowrap',
    flexShrink: '0',
    animate: {
      x: ['0%', '-100%'],
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    transition: {
      duration,
      ease: 'linear',
      times: [0, 1],
      repeat: Infinity,
      repeatDelay: 0,
    },
  };

  return (
    <Flex maxWidth="100%" flexWrap="nowrap" overflow="hidden" {...props}>
      <ChakraMotionBox {...commonMotionBoxProps}>{children}</ChakraMotionBox>
      <ChakraMotionBox {...commonMotionBoxProps}>{children}</ChakraMotionBox>
    </Flex>
  );
};
