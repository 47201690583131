export type LandingPageProps = {
  totalPooledCro: string | undefined;
  totalPooledCroLoading: boolean;
  tvlUsd: string | undefined;
  tvlUsdLoading: boolean;
  croPerStcro: string | undefined;
  croPerStcroLoading: boolean;
  ferroApy: number | undefined;
};

export type ActiveMockup = 'STAKE' | 'UNSTAKE' | 'LCRO';

export const HOW_IT_WORKS_BUTTONS = ['STAKE', 'LCRO', 'UNSTAKE'] as const;
