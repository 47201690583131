import { BigNumber } from '@ethersproject/bignumber';
import { parseUnits } from '@ethersproject/units';
import lcroMockupImage from '@public/landingMockups/lcro.png';
import stakeMockupImage from '@public/landingMockups/stake.png';
import unstakeMockupImage from '@public/landingMockups/unstake.png';
import { useQuery } from '@tanstack/react-query';
import { useDefaultAppBar } from '@ui/components/MobileAppBarProvider';
import { useApr, useApy } from '@ui/hooks/useApr';
import { useCoinUsdValue } from '@ui/hooks/useCoinUsdValue';
import { useTranslations } from '@ui/i18n';
import { useSpecificVenoSDK } from '@ui/providers/VenoSDKProvider';
import { getQuery } from '@ui/queries/queries';
import { QueryKey } from '@ui/queries/queryKey';
import aprToGrossApy from '@ui/utils/aprToGrossApy';
import { formatTokenAmount } from '@ui/utils/format';
import { ZKSYNC_ENABLED } from '@veno-app/wallet';
import type { StaticImageData } from 'next/image';
import type { ReactNode } from 'react';
import { useMemo, useState } from 'react';

import type { ActiveMockup } from './types';

export const useLandingPageStats = () => {
  const cronosSdk = useSpecificVenoSDK('cronos');
  const zksyncSdk = useSpecificVenoSDK('zksync');

  const { data: totalPooledCro, isLoading: totalPooledCroLoading } = useQuery({
    ...getQuery(QueryKey.TOTAL_POOLED)({
      sdk: cronosSdk,
      coinMode: 'cro',
    }),
  });
  const { data: totalPooledAtom, isLoading: totalPooledAtomLoading } = useQuery(
    {
      ...getQuery(QueryKey.TOTAL_POOLED)({
        sdk: cronosSdk,
        coinMode: 'atom',
      }),
    },
  );
  const { data: totalPooledEth, isLoading: totalPooledEthLoading } = useQuery({
    ...getQuery(QueryKey.TOTAL_POOLED)({
      sdk: zksyncSdk,
      coinMode: 'eth',
    }),
  });
  const { data: croTvlUsd, isLoading: croTvlUsdLoading } = useCoinUsdValue(
    totalPooledCro,
    'cro',
  );
  const { data: ethTvlUsd, isLoading: ethTvlUsdLoading } = useCoinUsdValue(
    totalPooledEth,
    'eth',
  );
  const { data: atomTvlUsd, isLoading: atomTvlUsdLoading } = useCoinUsdValue(
    totalPooledAtom,
    'atom',
  );

  const tvlUsd = useMemo(
    () =>
      croTvlUsd && atomTvlUsd && (!ZKSYNC_ENABLED || !!ethTvlUsd)
        ? formatTokenAmount(
            croTvlUsd.add(atomTvlUsd).add(ethTvlUsd ?? BigNumber.from(0)),
            2,
          ) + '$'
        : undefined,
    [atomTvlUsd, croTvlUsd, ethTvlUsd],
  );

  /**
   * @see https://app.sentio.xyz/cronos/veno/dashboards
   */
  const totalRewardPaid = parseUnits(String(775_000), 18);

  /**
   * @see https://app.sentio.xyz/cronos/veno/dashboards
   */
  const stakerCount = 17_000;

  return {
    totalPooledCro: totalPooledCro
      ? `${formatTokenAmount(totalPooledCro ?? BigNumber.from(0), 2)} CRO`
      : undefined,
    totalPooledCroLoading,
    tvlUsd,
    tvlUsdLoading:
      croTvlUsdLoading ||
      atomTvlUsdLoading ||
      totalPooledCroLoading ||
      totalPooledAtomLoading ||
      (ZKSYNC_ENABLED && (ethTvlUsdLoading || totalPooledEthLoading)),
    totalRewardPaid,
    stakerCount,
  };
};

export const useLandingPage = () => {
  useDefaultAppBar();
  const {
    totalPooledCro,
    totalPooledCroLoading,
    tvlUsd,
    tvlUsdLoading,
    totalRewardPaid,
    stakerCount,
  } = useLandingPageStats();

  const [activeMockup, setActiveMockup] = useState<ActiveMockup>('STAKE');

  const getButtonShadow = (label: ActiveMockup) => {
    return label === activeMockup ? 'card.active' : 'card.normal';
  };
  const getButtonAction = (label: ActiveMockup) => {
    return () => setActiveMockup(label);
  };
  const { data: apy, isLoading: apyLoading } = useApy();

  const { data: apr } = useApr();
  const grossApy = useMemo(() => aprToGrossApy(apr), [apr]);

  return {
    totalPooledCro,
    totalPooledCroLoading,
    tvlUsd,
    tvlUsdLoading,
    apy,
    apyLoading,
    getButtonAction,
    getButtonShadow,
    setActiveMockup,
    activeMockup,
    grossApy,
    totalRewardPaid,
    stakerCount,
  };
};

export const useHowItWorksData = () => {
  const t = useTranslations();

  const HOW_IT_WORKS: Record<
    ActiveMockup,
    {
      description: ReactNode;
      mockup: string | StaticImageData;
    }
  > = {
    STAKE: {
      description: t('how-it-works-stake'),
      mockup: stakeMockupImage,
    },
    UNSTAKE: {
      description: t('how-it-works-unstake'),
      mockup: unstakeMockupImage,
    },
    LCRO: {
      description: t('how-it-works-lcro'),
      mockup: lcroMockupImage,
    },
  };

  return HOW_IT_WORKS;
};
