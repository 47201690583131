import type { ChakraProps } from '@chakra-ui/react';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Text,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { DragDrawerContent } from '@ui/components/DragDrawer';
import Info from '@ui/components/Info/Info';
import { RotatingBg } from '@ui/components/RotatingBg';
import { SwiperWithScale } from '@ui/components/Swiper';
import { pathDict } from '@ui/config/paths';
import { useApy } from '@ui/hooks/useApr';
import { COIN_MODE } from '@ui/hooks/useCoinMode';
import useCoinPerStakedCoin from '@ui/hooks/useCoinPerStakedCoin';
import { useCoinUsdValue } from '@ui/hooks/useCoinUsdValue';
import { useIsMounted } from '@ui/hooks/useIsMounted';
import { useTranslations } from '@ui/i18n';
import Link from '@ui/overrides/next/link';
import { useSpecificVenoSDK } from '@ui/providers/VenoSDKProvider';
import { getQuery } from '@ui/queries/queries';
import { QueryKey } from '@ui/queries/queryKey';
import { formatTokenAmount } from '@ui/utils/format';
import { SwiperSlide } from 'swiper/react';

import { getVenoHighlights } from '../getVenoHighlights';
import { venoValidators } from '../utils';
import { VenoHighlightCard } from '../VenoHighlightCard';
import { useSupportedCoinDrawer } from './useSupportedCoinDrawer';
import { ValidatorCard } from './ValidatorCard';

export const SupportedCoinDrawer: React.FC = () => {
  const t = useTranslations();
  const coinMode = useSupportedCoinDrawer((s) => s.coinMode);
  const isOpen = useSupportedCoinDrawer((s) => s.isOpen);
  const onClose = useSupportedCoinDrawer((s) => s.onClose);
  const sdk = useSpecificVenoSDK(COIN_MODE[coinMode].chainType);
  const coinModeInfo = COIN_MODE[coinMode];
  const coinPathDict = pathDict[coinMode];
  const isMounted = useIsMounted();

  const totalPooledQueryResult = useQuery({
    ...getQuery(QueryKey.TOTAL_POOLED)({
      sdk,
      coinMode,
    }),
  });

  const totalPooledUsdQueryResult = useCoinUsdValue(
    totalPooledQueryResult.data,
    coinMode,
  );

  const apyQueryResult = useApy({
    coinMode,
  });

  const exchangeRatioQueryResult = useCoinPerStakedCoin(coinMode);

  const bgProps: ChakraProps =
    coinMode === 'atom'
      ? {
          backgroundImage:
            'url(/images/atom-landing-bg.png), url(/images/atom-landing-bg-2.svg)',
          backgroundSize: '100vw auto, 455px auto',
          backgroundPosition: '0px 80px, right 0px top 700px',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'local',
        }
      : coinMode === 'eth'
      ? {
          backgroundImage:
            'url(/images/eth-landing-bg.svg), url(/images/eth-landing-bg.svg)',
          backgroundSize: '100vw auto, 100vw auto',
          backgroundPosition: '0px 0px, 0px 700px',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'local',
        }
      : {
          backgroundImage:
            'url(/images/pattern-2.png), url(/images/pattern-4.png)',
          backgroundSize: '320px auto, 430px auto',
          backgroundPosition: '100% -40px, -90px 600px',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'local',
        };

  return (
    <Drawer
      placement="bottom"
      size="full"
      autoFocus={false}
      // avoid hydrate error
      isOpen={isMounted ? isOpen : false}
      onClose={onClose}
    >
      <DrawerOverlay />
      <DragDrawerContent position="relative">
        <DrawerHeader as="h1">
          {t('{coin} staking', {
            coin: coinModeInfo.name,
          })}
        </DrawerHeader>
        <DrawerBody {...bgProps} pb="140px">
          <Box
            shadow="card.active"
            w="50px"
            h="50px"
            rounded="full"
            p="1px"
            mx="auto"
          >
            <RotatingBg w="100%" h="100%" align="center" justify="center">
              <coinModeInfo.Icon w="38px" h="38px" />
            </RotatingBg>
          </Box>
          <Text textStyle="h3Bold" textAlign="center" mt="12px">
            {coinModeInfo.name}
          </Text>
          <Info
            title={t('Total value locked')}
            value={
              totalPooledQueryResult.data && (
                <Flex align="center">
                  <Text>
                    {formatTokenAmount(
                      totalPooledQueryResult.data,
                      2,
                      coinModeInfo.decimals,
                    )}
                  </Text>
                  <coinModeInfo.Icon w="24px" h="24px" />
                </Flex>
              )
            }
            convertedValueLoading={totalPooledUsdQueryResult.isLoading}
            convertedValue={
              totalPooledUsdQueryResult.data &&
              '$' + formatTokenAmount(totalPooledUsdQueryResult.data, 2)
            }
            mt="36px"
          />
          <Info
            title={t('Staking APY')}
            value={
              apyQueryResult.data === 0
                ? '--'
                : apyQueryResult.data &&
                  `${(apyQueryResult.data * 100).toFixed(2)}%`
            }
            mt="8px"
          />
          <Info
            title={t('You will receive')}
            value={
              exchangeRatioQueryResult.data &&
              `1 ${
                coinModeInfo.liquidName
              } = ${exchangeRatioQueryResult.data.toFixed(3)} ${
                coinModeInfo.name
              }`
            }
            mt="8px"
          />
          <Text textStyle="h3Bold" mt="50px">
            {t('Why Veno?')}
          </Text>
          <Text textStyle="bodySmall" mt="16px">
            {coinMode === 'cro' &&
              t(
                'Veno is the largest and most trusted protocol to liquid stake CRO',
              )}
            {coinMode === 'atom' &&
              t(
                'Veno offers access to the full Cronos ecosystem to use your {coin} on',
                {
                  coin: coinModeInfo.liquidName,
                },
              )}
            {coinMode === 'eth' &&
              t('Liquid Stake {coin} natively on {platform}', {
                coin: 'ETH',
                platform: 'ZkSync',
              })}
          </Text>
          <Box mx="-16px" mt="20px">
            <SwiperWithScale scaleEnabled={false} px="16px">
              {getVenoHighlights(t, coinMode).map((item, i) => {
                return (
                  <SwiperSlide key={i}>
                    <VenoHighlightCard
                      {...item}
                      height="calc(100% - 20px)"
                      my="8px"
                    />
                  </SwiperSlide>
                );
              })}
            </SwiperWithScale>
          </Box>
          <Text textStyle="h3Bold" mt="48px">
            {t('Our Validators')}
          </Text>
          <Text textStyle="bodySmall" mt="16px">
            {venoValidators[coinMode].length > 1
              ? t('{coin}-validator-desc', {
                  coin: coinModeInfo.name,
                })
              : venoValidators[coinMode][0].desc}
          </Text>
          {venoValidators[coinMode].length > 1 ? (
            <Box mx="-16px" mt="20px">
              <SwiperWithScale
                scaleEnabled={false}
                slidesPerView={2.3}
                px="16px"
              >
                {venoValidators[coinMode].map((item, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <ValidatorCard {...item} h="150px" mx="0" my="16px" />
                      <Text textStyle="h4Bold">{item.name}</Text>
                    </SwiperSlide>
                  );
                })}
              </SwiperWithScale>
            </Box>
          ) : (
            <>
              <ValidatorCard
                {...venoValidators[coinMode][0]}
                h="150px"
                mx="0"
                my="16px"
              />
              <Text textStyle="h4Bold">{venoValidators[coinMode][0].name}</Text>
            </>
          )}
        </DrawerBody>
        <DrawerFooter
          position="absolute"
          bottom="0"
          left="0"
          w="full"
          zIndex="1"
        >
          <Link href={coinPathDict.stake} passHref>
            <Button as="a">
              {t('Stake {coin}', {
                coin: coinModeInfo.name,
              })}
            </Button>
          </Link>
        </DrawerFooter>
      </DragDrawerContent>
    </Drawer>
  );
};
