import { AspectRatio, Flex, Link } from '@chakra-ui/react';
import { Card } from '@ui/components/Card';
import { EXTERNAL_LINK } from '@ui/config/externalLinks';
import { useTranslations } from '@ui/i18n';
import NextImage from 'next/image';

import SlowmistLogo from './images/slowmist.png';

const AuditCard = () => {
  const t = useTranslations();
  return (
    <Card mx={0} px={4} pb={4} mt={4} mb={0}>
      <Flex flexDir="column">
        <AspectRatio ratio={SlowmistLogo.width / SlowmistLogo.height}>
          <NextImage alt="Slowmist" src={SlowmistLogo} fill sizes="100vw" />
        </AspectRatio>
        <Link href={EXTERNAL_LINK.audit} color="pink.500" isExternal>
          {t('See report')}
        </Link>
      </Flex>
    </Card>
  );
};

export default AuditCard;
