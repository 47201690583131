import type { BoxProps, ChakraProps } from '@chakra-ui/react';
import { Box, Button, Flex, Skeleton, Text } from '@chakra-ui/react';
import type { CardProps } from '@ui/components/Card';
import { Card } from '@ui/components/Card';
import { InMobile } from '@ui/components/MobileOrDesktop';
import { RotatingBg } from '@ui/components/RotatingBg';
import { pathDict } from '@ui/config/paths';
import { COIN_MODE } from '@ui/hooks/useCoinMode';
import { useTranslations } from '@ui/i18n';
import Link from '@ui/overrides/next/link';
import { formatTokenAmount } from '@ui/utils/format';
import type { ReactNode } from 'react';

import { useSupportedCoinDrawer } from './SupportedCoinPage/useSupportedCoinDrawer';
import type { SupportedCoinInfo } from './utils';

export type SupportedCoinCardProps = Omit<CardProps, 'title'> &
  SupportedCoinInfo;
export const SupportedCoinCard = ({
  coinMode,
  title,
  desc,
  tvl,
  apy,
  networkIcon,
  ...props
}: SupportedCoinCardProps) => {
  const t = useTranslations();
  const openSupportedCoinDrawer = useSupportedCoinDrawer((s) => s.onOpen);
  const coinModeInfo = COIN_MODE[coinMode];

  const bgProps: ChakraProps =
    coinMode === 'atom'
      ? {
          backgroundImage: '/images/atom-landing-bg.png',
          backgroundSize: '100% auto',
          backgroundPosition: '0px -50px',
          backgroundRepeat: 'no-repeat',
        }
      : coinMode === 'eth'
      ? {
          backgroundImage: '/images/eth-landing-bg.svg',
          backgroundSize: '100% auto',
          backgroundPosition: '0px -110px',
          backgroundRepeat: 'no-repeat',
        }
      : {
          backgroundImage: '/images/pattern-4.png',
          backgroundSize: '320px auto',
          backgroundPosition: '-150px -50px',
          backgroundRepeat: 'no-repeat',
        };

  return (
    <Card
      p="12px 16px 20px"
      mx="0"
      textAlign="center"
      position="relative"
      {...bgProps}
      {...props}
    >
      <Box position="absolute" right="8px" top="8px" textAlign="right">
        <Text fontSize="8px" fontWeight="bold">
          {t('Network')}
        </Text>
        {networkIcon}
      </Box>
      <Box
        shadow="card.active"
        w="50px"
        h="50px"
        rounded="full"
        p="1px"
        mx="auto"
      >
        <RotatingBg w="100%" h="100%" align="center" justify="center">
          <coinModeInfo.Icon w="38px" h="38px" />
        </RotatingBg>
      </Box>
      <InMobile>
        <Text textStyle="ctaCopy">{coinModeInfo.name}</Text>
      </InMobile>
      <Text
        textStyle={{
          base: 'ctaCopy',
          desktop: 'h4Bold',
        }}
        mt="16px"
      >
        {title}
      </Text>
      <Text
        textStyle={{
          base: 'bodySmall',
          desktop: 'h4',
        }}
        mt="4px"
        minHeight="84px"
        lineHeight="150%"
      >
        {desc}
      </Text>
      <Flex gap="40px" justify="center" mt="8px">
        <InfoBox
          title="TVL"
          content={
            tvl && `$${formatTokenAmount(tvl, 2, undefined, undefined, true)}`
          }
        />
        <InfoBox
          title="APY"
          content={apy === 0 ? '--' : apy && `${(apy * 100).toFixed(2)}%`}
        />
      </Flex>
      <Flex gap="21px" justify="center" mt="20px">
        <Link href={pathDict[coinMode].stake} passHref>
          <Button
            as="a"
            size={{
              base: 'sm',
              desktop: 'md',
            }}
          >
            {t('Stake {coin}', {
              coin: coinModeInfo.name,
            })}
          </Button>
        </Link>
        <Button
          variant="link"
          color="text-link"
          size={{
            base: 'sm',
            desktop: 'md',
          }}
          onClick={() => {
            openSupportedCoinDrawer({
              coinMode,
            });
          }}
        >
          {t('Learn more')}
        </Button>
      </Flex>
    </Card>
  );
};

const InfoBox = ({
  title,
  content,
  ...props
}: BoxProps & { title: ReactNode; content: ReactNode }) => {
  return (
    <Box {...props}>
      <Text
        textStyle={{
          desktop: 'h4',
        }}
      >
        {title}
      </Text>
      <Skeleton isLoaded={!!content}>
        <Text
          textStyle={{
            base: 'h4Bold',
            desktop: 'h3Bold',
          }}
          color="brand-primary"
          mt="4px"
        >
          {content ?? '------'}
        </Text>
      </Skeleton>
    </Box>
  );
};
