import { pathDict } from '@ui/config/paths';
import { getPathInfo, useRealPathname } from '@ui/hooks/router';
import type { CoinMode } from '@ui/hooks/useCoinMode';
import { pushRouterWithoutRerender } from '@ui/utils/urlHistory';

export type UseSupportedCoinDrawerStore = {
  isOpen: boolean;
  coinMode: CoinMode;
  onOpen: (options: { coinMode: CoinMode }) => void;
  onClose: () => void;
};

export const useSupportedCoinDrawer = <U,>(
  selector: (state: UseSupportedCoinDrawerStore) => U,
): U => {
  const realPathInfo = getPathInfo(useRealPathname());

  return selector({
    isOpen: realPathInfo.category === 'landingDetail',
    coinMode:
      realPathInfo.category === 'landingDetail' ? realPathInfo.coinMode : 'cro',
    onOpen: ({ coinMode }) => {
      pushRouterWithoutRerender(pathDict[coinMode].landingDetail);
    },
    onClose: () => {
      pushRouterWithoutRerender(pathDict.cro.landing);
    },
  });
};
